export default {
  namespaced: true,
  state: () => ({
    showModal: false,
    title: "",
    message: "",
    path: "",
    icon: "",
    btnOk: false
  }),
  mutations: {
    setShowModal(state, value) {
      state.showModal = value;
    },
    setTitle(state, value) {
      state.title = value;
    },
    setMessage(state, value) {
      state.message = value;
    },
    setSetTextButton(state, value) {
      state.textButton = value;
    },
    setPath(state, value) {
      state.path = value;
    },
    setIcon(state, value) {
      state.icon = value;
    },
    setBtnOk(state, value) {
      state.btnOk = value
    }
  },
  actions: {
    showModal({ commit }) {
      const urlParams = new URLSearchParams(window.location.search);
      const slug = urlParams.get("bookSlug");
      commit("setShowModal", true);
      commit("setTitle", "Interromper leitura?");
      commit(
        "setMessage",
        "Ao sair do modo de leitura o sistema memorizará sua página atual automaticamente para que você possa retomar a leitura deste titulo futuramente, ok?"
      );
      commit(
        "setPath",
        process.env.VUE_APP_APP_URL + "/catalogo/detalhes/" + slug
      );
      commit("setIcon", "");
      commit("setBtnOk", false);
    },

    showModalEpubNotExist({ commit }) {
      const urlParams = new URLSearchParams(window.location.search);
      const slug = urlParams.get("bookSlug");
      commit("setShowModal", true);
      commit("setTitle", "Livro não foi encontrado!");
      commit(
        "setMessage",
        "Pedimos desculpas pelo transtorno, estamos trabalhando par amelhorar ainda mais sua experiência como nosso leitor."
      );
      commit("setIcon", "ops.gif");
      commit("setBtnOk", true);
    },
  },
  modules: {},
};
