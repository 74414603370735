<template>
  <div class="content">
    <v-list dense class="mt-5">
      <v-list-item>
        <v-sheet color="transparent" class="nav-title mx-13">
          <div>Marcadores</div>
        </v-sheet>
      </v-list-item>
    </v-list>

    <v-list nav dense class="text-body pt-10">
      <v-list-item-group active-class="text-bold text--accent-4">
        <v-list-item
          v-for="(item, index) of $store.state.book.pagesSaved"
          :key="index"
          @click="to(item.path)"
        >
          <li>{{ item.chapter }} página {{ item.page }} de {{ item.total }}</li>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  methods: {
    to(path) {
      this.$store.dispatch("book/to", path);
      this.$store.commit("sidebar/setShow", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-title {
  font-size: 1.5rem;
  color: $gray6;
  font-family: roboto slab;
}
.text-body {
  font-size: 1.125rem !important;
}
.text-bold {
  font-weight: bold;
}

@media only screen and (min-width: 400px) and (max-width: 720px) {
  .nav-title {
    font-size: 2.5rem !important;
  }
  .text-body {
    font-size: 2rem !important;
  }
}
</style>