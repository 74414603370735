export default {
  namespaced: true,
  state: () => ({
    show: false
  }),
  mutations: {
    setShow(state, value) {
      state.show = value
    }
  },
  actions: {
    toggle({ state, commit }) {
      commit('setShow', !state.show)
    }
  },
}