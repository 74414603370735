import Vue from 'vue'
import Vuex from 'vuex'
import modalWarning from './modalWarning'
import book from './book'
import sidebar from './sidebar'
import topbar from './topbar'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    modalWarning,
    book,
    sidebar,
    topbar
  }
})
