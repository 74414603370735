export default {
  namespaced: true,
  state: () => ({
    bookmark: false
  }),
  mutations: {
    setBookmark(state, value) {
      state.bookmark = value
    }
  }
}