import { instance, persist } from "../../services"
import makeRangeCfi from "./make-range-cfi"

export default {
  namespaced: true,
  state: () => ({
    book: '',
    pages: [],
    page: '',
    display: null,
    title: '',
    author: '',
    pagesSaved: [],
    words: '',
    timeout: process.env.VUE_APP_TIMEOUT_PAGE,
    token: '',
    bookId: 0
  }),
  mutations: {
    setBookId(state, value) {
      state.bookId = value
    },
    addPagesSaved(state, value) {
      state.pagesSaved.push(value)
    },
    setPagesSaved(state, value) {
      state.pagesSaved = value
    },
    setBook(state, value) {
      state.book = value
    },
    setPages(state, value = []) {
      state.pages = value
    },
    setPage(state, value) {
      state.page = value
    },
    setDisplay(state, value) {
      state.display = value
    },
    setMeta(state, meta) {
      state.title = meta.title
      state.author = meta.creator
    },
    removePageSaved(state, page) {
      state.pagesSaved = state.pagesSaved.filter(item => item.path !== page)
    },
    setToken(state, value) {
      state.token = value
      instance()
    }
  },
  actions: {
    changeBook({ commit }, book) {
      commit('setBook', book)
      commit('setPages', book.navigation.toc)
      const currentLocation = book.rendition.currentLocation()
      commit('setPage', currentLocation.start.cfi)
    },
    setDisplay({ commit }, display) {
      commit('setDisplay', display)
    },
    to({ state }, href) {
      state.display.display(href)
    },
    verifyPagesMarker({ state }) {
      this.commit('topbar/setBookmark', !!state.pagesSaved.find(item => item.path === state.book.rendition.currentLocation().start.cfi))
    },

    async addPermanentPage({ commit, state }, bookId) {
      const currentLocation = state.book.rendition.currentLocation()
      const exists = state.pagesSaved.find(item => item.path === currentLocation.start.cfi)
      if (exists) return null

      const [a, b] = [currentLocation.start.cfi, currentLocation.end.cfi]
      await state.book.getRange(makeRangeCfi(a, b)).then(range => {
        return persist(bookId, {
          page: currentLocation.start.cfi,
          words: range.toString()
        }).catch(err => console.error(err))
      })
    },

    addPagesSaved({ commit, state }) {
      const currentLocation = state.book.rendition.currentLocation()
      const chapter = currentLocation.start.href.replaceAll('.xhtml', '')

      const exists = state.pagesSaved.find(item => item.path === currentLocation.start.cfi)
      if (exists) return null

      const [a, b] = [currentLocation.start.cfi, currentLocation.end.cfi]
      return state.book.getRange(makeRangeCfi(a, b)).then(async () => {

        await commit('addPagesSaved', {
          chapter: chapter.includes('chapter')
            ? chapter.split('_')[0] + '-' + Number(chapter.split('_')[1])
            : chapter,
          path: currentLocation.start.cfi,
          page: currentLocation.start.displayed.page,
          total: currentLocation.start.displayed.total,
        })

        return persist(state.bookId, { bookmarks: state.pagesSaved }).catch(console.error)
      })
    },

    async removePageSaved({ commit, state }) {
      await commit('removePageSaved', state.book.rendition.currentLocation().start.cfi)
      await this.commit('topbar/setBookmark', !!state.pagesSaved.find(item => item.path === state.book.rendition.currentLocation().start.cfi))
      return persist(state.bookId, { bookmarks: state.pagesSaved }).catch(console.error)
    }

  }
}