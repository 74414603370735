<template>
  <v-app>
    <Loader v-model="showBook"></Loader>
    <template v-show="showBook">
      <top-bar @click="$store.dispatch('sidebar/toggle')"></top-bar>
      <v-main>
        <div id="content" ref="content" class="mx-auto"></div>
        <div
          id="prev"
          class="arrow"
          v-show="['lg', 'xl'].includes($vuetify.breakpoint.name)"
        >
          ‹
        </div>
        <div
          id="next"
          class="arrow"
          v-show="['lg', 'xl'].includes($vuetify.breakpoint.name)"
        >
          ›
        </div>
      </v-main>
      <side-bar></side-bar>
      <ModalWarning @saveAndClose="saveAndClose" />
    </template>
  </v-app>
</template>

<script>
import epubjs from "epubjs";
import TopBar from "./components/TopBar/TopBar.vue";
import SideBar from "./components/Sidebar/Sidebar.vue";
import ModalWarning from "./components/Modals/ModalWarning.vue";
import Loader from "./components/Loader/Loader.vue";
import { getBook, getEpub } from "./services/index";
export default {
  name: "App",
  components: { TopBar, SideBar, ModalWarning, Loader },
  data: () => ({
    showSideBar: false,
    showArrows: false,
    showBook: false,
    pages: [],
    timeout: 0,
    timeoutLimit: process.env.VUE_APP_TIMEOUT_PAGE * 1000,
  }),
  methods: {
    saveAndClose() {
      if (this.$store.state.modalWarning.path) {
        this.$store.dispatch(
          "book/addPermanentPage",
          this.$store.state.book.bookId
        );
        window.location.href = this.$store.state.modalWarning.path;
      }
    },
    pageTimeout() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(
        () =>
          this.$store.dispatch(
            "book/addPermanentPage",
            this.$store.state.book.bookId
          ),
        this.timeoutLimit
      );
    },
  },
  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const slug = urlParams.get("bookSlug");
    const initial = urlParams.get("initial") === "true";
    this.$store.commit("book/setToken", token);

    const bookData = await getBook(slug, token).catch((err) => false);
    if (!bookData)
      return this.$store.dispatch("modalWarning/showModalEpubNotExist");
    this.$store.commit("book/setBookId", bookData.id);
    if (bookData.user_book_history?.bookmarks) {
      this.$store.commit(
        "book/setPagesSaved",
        bookData.user_book_history.bookmarks
      );
    }

    const epub = await getEpub(bookData.id).catch(() => false);
    if (!epub) {
      return this.$store.dispatch("modalWarning/showModalEpubNotExist");
    }

    var book = epubjs(epub);

    var rendition = book.renderTo("content", {
      manager: "continuous",
      flow: "paginated",
      width: "100%",
      height: "100%",
      snap: true,
      spread: "none",
      allowScriptedContent: true,
    });
    // let location = this.rendition.currentLocation();
    // let cfiString = location.start.cfi; // this is what you want to save

    var displayed = rendition.display();

    rendition.on("relocated", (cfi) => {
      this.pageTimeout();
      this.$store.dispatch("book/verifyPagesMarker", cfi);
    });

    displayed.then((section) => {
      // -- do stuff
      book.loaded.metadata.then(async (meta) => {
        await this.$store.dispatch("book/changeBook", book);
        await this.$store.dispatch("book/setDisplay", rendition);
        this.$store.commit("book/setMeta", meta);
        this.showBook = true;
        if (!initial && bookData.user_book_history) {
          this.$store.dispatch("book/to", bookData.user_book_history.page);
        }
      });
    });

    // Navigation loaded
    book.loaded.navigation.then((toc) => {
      // console.log(toc);
    });

    const next = document.getElementById("next");
    next.addEventListener(
      "click",
      function () {
        rendition.next();
      },
      false
    );

    const prev = document.getElementById("prev");
    prev.addEventListener(
      "click",
      function () {
        rendition.prev();
      },
      false
    );

    document.addEventListener(
      "keyup",
      function (e) {
        // Left Key
        if ((e.keyCode || e.which) == 37) {
          rendition.prev();
        }

        // Right Key
        if ((e.keyCode || e.which) == 39) {
          rendition.next();
        }
      },
      false
    );
  },
};
</script>
<style scoped>
#content {
  max-width: 1024px;
  width: 100%;
  height: 100%;
}

#content > * {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-size: 26px !important;
  line-height: 34px;
  text-align: center;
}

.arrow {
  position: fixed;
  top: 50%;
  margin-top: -32px;
  font-size: 64px;
  color: #e2e2e2;
  font-family: arial, sans-serif;
  font-weight: bold;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  text-decoration: none;
}
#prev {
  left: 10px;
}
#next {
  right: 10px;
}
</style>
<style >
</style>