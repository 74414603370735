<template>
  <div class="content">
    <v-list dense class="mt-5">
      <v-list-item>
        <v-sheet color="transparent" class="nav-title mx-6">
          <div>{{ $store.state.book.title }}</div>
          <div
            style="
              font-weight: 100;
              font-size: 1.3rem;
              font-family: roboto slab light;
              font-style: normal;
            "
          >
            {{ $store.state.book.author }}
          </div>
        </v-sheet>
      </v-list-item>
    </v-list>

    <v-list nav dense class="text-body pt-10">
      <v-list-item-group active-class="text-bold text--accent-4">
        <v-list-item
          v-for="(item, index) of $store.state.book.pages"
          :key="index"
          @click="to(item.href)"
        >
          <li>{{ item.label }}</li>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  methods: {
    to(path) {
      this.$store.dispatch("book/to", path.replace("../", ""));
      this.$store.commit("sidebar/setShow", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-title {
  font-weight: 400;
  font-size: 1.5rem;
  color: $gray6;
  font-family: roboto slab;
}
.text-body {
  font-size: 1.125rem !important;
}
.text-bold {
  font-weight: 700;
}

@media only screen and (min-width: 400px) and (max-width: 720px) {
  .nav-title {
    font-size: 2.5rem !important;
  }
  .text-body {
    font-size: 2rem !important;
  }
}
</style>