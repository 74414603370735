<template>
  <v-app-bar class="pa-3" app fixed elevation="0" color="#ffffff" height="80px">
    <v-app-bar-nav-icon @click="$emit('click')">
      <template #default>
        <v-icon x-large> mdi-format-list-bulleted </v-icon>
      </template>
    </v-app-bar-nav-icon>

    <v-spacer></v-spacer>

    <v-toolbar-title class="topbar-title">
      <div class="title-text truncate">
        {{ $store.state.book.title }}
      </div>
      <div class="autors">{{ $store.state.book.author }}</div>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn v-if="!$store.state.topbar.bookmark" icon @click="bookmark">
      <v-icon x-large>mdi-bookmark-outline</v-icon>
    </v-btn>

    <v-btn v-else icon @click="removeBookmark">
      <v-icon x-large>mdi-bookmark</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  methods: {
    async bookmark() {
      await this.$store.dispatch("book/addPagesSaved", true);
      await this.$store.dispatch("book/verifyPagesMarker");
    },
    removeBookmark() {
      this.$store.dispatch("book/removePageSaved");
    },
  },
};
</script>

<style  scoped lang="scss">
.topbar-title {
  text-align: center;
  color: $gray2;
  font-size: 1.75rem;
}
.title-text {
  font-weight: bold;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.autors {
  font-weight: 100;
  font-size: 1rem;
}
</style>