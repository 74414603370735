<template>
  <v-navigation-drawer
    v-model="show"
    class="nav-bar"
    fixed
    :mobile-breakpoint="5000"
    :permanent="permanent"
    dark
    :height="windowHeight"
    width="35.375rem"
  >
    <v-list-item class="nav-top">
      <v-list-item-content>
        <v-list-item-title class="text-h1">
          <v-row no-gutters justify="center" class="top-icons">
            <v-col :style="{ opacity: allSessions ? 1 : 0.3 }">
              <span @click="allSessions = true" style="cursor: pointer">
                <v-icon>mdi-format-list-bulleted</v-icon>
              </span>
            </v-col>

            <v-col :style="{ opacity: allSessions ? 0.3 : 1 }">
              <span @click="allSessions = false" style="cursor: pointer">
                <v-icon>mdi-bookmark</v-icon>
              </span>
            </v-col>

            <v-col class="mt-3" align-self="center" align="right"
              ><DoorClose @click="showModal"
            /></v-col>
          </v-row>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <AllSessions v-if="allSessions" />
    <MarkedSessions v-if="!allSessions" />

    <v-footer v-if="false" class="justify-left side-footer pl-10"
      >BookStream © 2022 <br />
      Desenvolvido por Phixies.com</v-footer
    >
  </v-navigation-drawer>
</template>

<script>
import AllSessions from "../AllSessions/AllSessions.vue";
import MarkedSessions from "../MarkedSessions/MarkedSessions.vue";
import DoorClose from "../svgs/DoorClose.vue";
export default {
  components: { DoorClose, AllSessions, MarkedSessions },
  data: () => ({
    group: null,
    allSessions: true,
    permanent: false,
  }),
  methods: {
    showModal() {
      this.permanent = true;
      this.$store.dispatch("modalWarning/showModal");
    },
  },
  computed: {
    windowHeight() {
      return window.innerHeight;
    },
    show: {
      get() {
        return this.$store.state.sidebar.show;
      },
      set(value) {
        this.$store.commit("sidebar/setShow", value);
      },
    },
  },
  watch: {
    "$store.state.modalWarning.showModal"(value) {
      this.permanent = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-bar {
  background: $gray2;
}

.nav-top {
  background: $gray1;
  position: fixed;
  width: 100%;
  z-index: 1;
}
.top-icons * {
  font-size: 3.75rem;
}

.side-footer {
  font-weight: 300 !important;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
}
</style>