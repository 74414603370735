import { render, staticRenderFns } from "./DoorClose.vue?vue&type=template&id=926c2180&scoped=true&"
import script from "./DoorClose.vue?vue&type=script&lang=js&"
export * from "./DoorClose.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "926c2180",
  null
  
)

export default component.exports