<template>
  <v-dialog v-model="dialog" width="20rem">
    <v-card class="max-auto" width="20rem" height="19.355 rem">
      <v-card-title>
        <v-spacer></v-spacer
        ><v-img width="1rem" :src="$store.state.modalWarning.icon"></v-img
        ><v-spacer> </v-spacer>
      </v-card-title>
      <v-card-title class="text-title pt-0 pb-6">
        <v-spacer></v-spacer>{{ $store.state.modalWarning.title
        }}<v-spacer></v-spacer>
      </v-card-title>

      <v-card-text class="text-content">
        {{ $store.state.modalWarning.message }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="pa-5">
        <v-sheet
          v-if="!$store.state.modalWarning.btnOk"
          v-ripple
          style="cursor: pointer"
          color="transparent"
          class="button-action gray4"
          text
          @click="dialog = false"
        >
          Permanecer
        </v-sheet>

        <v-spacer v-if="!$store.state.modalWarning.btnOk" />

        <v-sheet
          v-if="!$store.state.modalWarning.btnOk"
          v-ripple
          style="cursor: pointer"
          color="transparent"
          class="button-action gray2"
          text
          @click="actions"
        >
          Salvar e Sair
        </v-sheet>
        <v-spacer v-if="$store.state.modalWarning.btnOk"></v-spacer>
        <v-sheet
          v-if="$store.state.modalWarning.btnOk"
          v-ripple
          style="cursor: pointer"
          color="transparent"
          class="button-action gray2"
          text
          @click="close"
        >
          OK
        </v-sheet>
        <v-spacer v-if="$store.state.modalWarning.btnOk"></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "modalWarning",
  computed: {
    dialog: {
      get() {
        return this.$store.state.modalWarning.showModal;
      },
      set(value) {
        this.$store.commit("modalWarning/setShowModal", value);
      },
    },
  },
  methods: {
    actions() {
      this.$emit("saveAndClose");
      this.dialog = false;
    },
    close() {
      window.location.href = "https://bibliotecananuvem.com.br/catalogo";
    },
  },
};
</script>

<style lang="scss" scoped>
.button-action {
  font-weight: 700;
  font-size: 1.29rem;
}
.gray4 {
  color: $gray4;
}
.gray2 {
  color: $gray2;
}
.text-title {
  font-weight: 500;
  font-size: 1.29rem !important;
  text-align: center;
  color: $gray1;
}
.text-content {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  text-align: center;
  color: $gray3;
}
.v-divider {
  border-color: rgba(0, 0, 0, 0.5);
}
</style>
