<template>
  <v-dialog fullscreen v-model="showModal">
    <v-sheet
      width="100%"
      height="100%"
      class="d-flex align-center justify-center"
      align="center"
    >
      <v-container fluid class="pa-0">
        <v-row no-gutters align="center">
          <v-col
            cols="6"
            align="right"
            align-self="center"
            style="padding: 10px"
          >
            <v-img max-width="179px" src="@/assets/book.svg" />
          </v-col>
          <v-col
            cols="6"
            align="left"
            align-self="center"
            style="padding: 10px"
          >
            <v-img max-width="265px" src="@/assets/BookStream.svg" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-sheet width="100%" class="ad-flex align-center justify-center">
              <v-spacer></v-spacer>
              <v-img
                width="28.125rem"
                height="19.125rem"
                src="@/assets/loader.gif"
              />
              <v-spacer></v-spacer>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="prepare"> Prepare-se para sua leitura! </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  props: ["value"],

  computed: {
    showModal: {
      get() {
        return !this.value;
      },
      set(value) {
        this.$emit("value", value);
      },
    },
  },
};
</script>
<style scoped lang="scss">
.prepare {
  font-family: "Roboto Slab";
  font-weight: 500;
  font-size: 2.5rem;
  text-align: center;
  color: $gray1;
}
</style>