<template>
  <v-img
    style="cursor: pointer"
    @click="$emit('click')"
    width="3.125rem"
    src="@/assets/door-close.svg"
  ></v-img>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>